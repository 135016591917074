export const PATH_FRAGMENT__ADMINS = '/admins' as const;
export const PATH_FRAGMENT__BUSINESS = '/business' as const;
export const PATH_FRAGMENT__CLIENTS = '/clients' as const;
export const PATH_FRAGMENT__COACHES = '/coaches' as const;
export const PATH_FRAGMENT__ERROR = '/error' as const;
export const PATH_FRAGMENT__EXERCISES = '/exercises' as const;
export const PATH_FRAGMENT__FACILITIES = '/facilities' as const;
export const PATH_FRAGMENT__HOME = '/home' as const;
export const PATH_FRAGMENT__LIBRARY = '/library' as const;
export const PATH_FRAGMENT__ME = '/me' as const;
export const PATH_FRAGMENT__ONBOARDING = '/onboarding' as const;
export const PATH_FRAGMENT__PROXY = '/proxy' as const;
export const PATH_FRAGMENT__ROOT = '/' as const;
export const PATH_FRAGMENT__ROUTINES = '/routines' as const;
export const PATH_FRAGMENT__SETTINGS = '/settings' as const;
export const PATH_FRAGMENT__WORKOUTS = '/workouts' as const;
export const PATH_FRAGMENT__PROFILE = '/profile' as const;
export const PATH_FRAGMENT__SUBSCRIPTIONS = '/subscriptions' as const;
export const PATH_FRAGMENT__MORE = '/more' as const;

// Dynamic path fragments
export const PATH_FRAGMENT__ADMIN_ID = '/:adminId' as const;
export const PATH_FRAGMENT__CLIENT_ID = '/:clientId' as const;
export const PATH_FRAGMENT__COACH_ID = '/:coachId' as const;
export const PATH_FRAGMENT__ERROR_TYPE = '/:error' as const;
export const PATH_FRAGMENT__EXERCISE_ID = '/:exerciseId' as const;
export const PATH_FRAGMENT__FACILITY_ID = '/:facilityId' as const;
export const PATH_FRAGMENT__PRODUCT_TYPE = '/:productType' as const;
export const PATH_FRAGMENT__PROXY_TYPE = '/:proxyType' as const;
export const PATH_FRAGMENT__ROUTINE_ID = '/:routineId' as const;
export const PATH_FRAGMENT__WORKOUT_ID = '/:workoutId' as const;

export type PathFragment =
  | typeof PATH_FRAGMENT__ADMIN_ID
  | typeof PATH_FRAGMENT__ADMINS
  | typeof PATH_FRAGMENT__BUSINESS
  | typeof PATH_FRAGMENT__CLIENT_ID
  | typeof PATH_FRAGMENT__CLIENTS
  | typeof PATH_FRAGMENT__COACH_ID
  | typeof PATH_FRAGMENT__COACHES
  | typeof PATH_FRAGMENT__ERROR
  | typeof PATH_FRAGMENT__ERROR_TYPE
  | typeof PATH_FRAGMENT__EXERCISE_ID
  | typeof PATH_FRAGMENT__EXERCISES
  | typeof PATH_FRAGMENT__FACILITIES
  | typeof PATH_FRAGMENT__FACILITY_ID
  | typeof PATH_FRAGMENT__HOME
  | typeof PATH_FRAGMENT__LIBRARY
  | typeof PATH_FRAGMENT__ME
  | typeof PATH_FRAGMENT__ONBOARDING
  | typeof PATH_FRAGMENT__PRODUCT_TYPE
  | typeof PATH_FRAGMENT__PROFILE
  | typeof PATH_FRAGMENT__PROXY
  | typeof PATH_FRAGMENT__PROXY_TYPE
  | typeof PATH_FRAGMENT__ROOT
  | typeof PATH_FRAGMENT__ROUTINE_ID
  | typeof PATH_FRAGMENT__ROUTINES
  | typeof PATH_FRAGMENT__SETTINGS
  | typeof PATH_FRAGMENT__SUBSCRIPTIONS
  | typeof PATH_FRAGMENT__WORKOUT_ID
  | typeof PATH_FRAGMENT__WORKOUTS;
