import { createContext, FC, PropsWithChildren, RefObject, useRef } from 'react';

export const ScrollContainerContext = createContext<
  RefObject<HTMLDivElement> | undefined
>(undefined);

const ScrollContainerProvider: FC<PropsWithChildren> = ({ children }) => {
  const ref: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  return (
    <ScrollContainerContext.Provider value={ref}>
      {children}
    </ScrollContainerContext.Provider>
  );
};

export default ScrollContainerProvider;
