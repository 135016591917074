export const translationsAuth = {
  forgotPassword: {
    label: 'Forgot Password',
    linkSignIn: 'Back to Sign In',
    success: {
      info: 'A password reset email has been sent to your account. Please check your email to reset your password.',
      title: 'Password Reset Email Sent',
    },
  },
  newPasswordChallenge: {
    label: 'New Password',
    success: {
      info: 'You can use your new credentials to log into the mobile app.',
      title: 'You have successfully changed your password',
    },
  },
  resetPassword: {
    label: 'Reset Password',
    success: {
      info: 'Your password has been successfully reset. You can now use your new password to sign in.',
      title: 'Password Reset Successful',
    },
  },
  signIn: {
    label: 'Sign In',
    linkForgotPassword: 'Forgot Password?',
    linkSignUp: 'Create a new account',
  },
  signUp: {
    business: 'Business',
    individual: 'Individual',
    label: 'Sign Up',
    linkLogin: 'Already have an account? Go to Login.',
  },
  verifyCode: {
    error: 'Something went wrong',
    label: 'Verify Code',
    success: {
      info: 'Redirecting to sign in...',
      title: 'Verification was successful.',
    },
  },
};
